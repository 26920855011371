.seeMore_btn{
    width: 200px;
    border-style: solid;
    border-radius: 8px;
    border-width: 0px;
    background-color: #4FA0C8;
    
}

@media  screen and (max-width: 1000px) and (min-width: 0px){
    .products_bg{
        background-image: url("../../photos/aboutus_bg_mobile.png");
        background-position:  right;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        
        overflow: hidden;
    }
    .products_bg_ar{
        background-image: url("../../photos/aboutus_bg_mobile.png");
        background-position:  right;
        background-repeat: no-repeat;
        background-size: 100% 100%;
       
        overflow: hidden;
    }
    .products_sec2 h4{
        color: #004B6E;
        text-decoration: underline;
        text-decoration-color: #004B6E ;
        text-underline-offset: 14px;
      }
   
    .Aboutus_text{
        margin-top: 200px;
    }
    .Aboutus_text h3{
        color: #004B6E;
        text-decoration: underline;
        text-decoration-color: #004B6E ;
        text-underline-offset: 14px;
      }
    .Aboutus_text h1{
        font-size: 40px;
    }
    .Aboutus_text p{
        font-size: 13px;
    }
    .about_img{
        width: 400px;
    }
    .about_show{
    display: none;
    }
    .about_text1{
        margin-top: 40px;
        margin-inline-start: 50px;
    }
    .aboutus_sec4 li{
        position: relative;
        color: #004B6E;
        font-size: 20px;
    }

  }

  @media  screen  and (min-width: 1001px) {
    .products_bg{
        background-image: url("../../photos/products-sec1_bg.png");
        background-position:  right;
        background-repeat: no-repeat;
        background-size: 100% 100%;
       
        overflow: hidden;
        
    }
   .products_bg_ar{
    background-image: url("../../photos/products-sec1_bg_ar.png");
    background-position:  right;
    background-repeat: no-repeat;
    background-size: 100% 100%;
   
    overflow: hidden;
   }
    .products_sec2 h4{
        color: #004B6E;
        text-decoration: underline;
        text-decoration-color: #004B6E ;
        text-underline-offset: 14px;
      }
  
    .Aboutus_text{
        margin-top: 200px;
    }
    .Aboutus_text h3{
        color: #004B6E;
        text-decoration: underline;
        text-decoration-color: #004B6E ;
        text-underline-offset: 14px;
      }
    .Aboutus_text h1{
        font-size: 40px;
    }
    .Aboutus_text p{
        font-size: 13px;
    }
    .about_img{
        width: 400px;
    }
    .about_show{
    display: none;
    }
    .about_text1{
        margin-top: 40px;
        margin-inline-start: 50px;
    }
    .aboutus_sec4 li{
        position: relative;
        color: #004B6E;
        font-size: 20px;
    }

  }  

 