*{
  word-break: break-word;
}
input:focus {
  outline: none; 
  }
.left_to_right{
    direction: ltr;
}
.right-to-left{
    direction: rtl;
}

.floating_whatssapp{
  position: fixed;
  right: 30px;
  bottom:50px ;

}
.whatssapp_icon{
  font-size: 50px;
  color: green;
}
.sec3_underline{
  width: 100%;
  height: 2px;
  background-color:rgba(0, 0, 0, 0.253) ;
}


@media   screen  and (min-width:  1001px) {
  .display_hide2_sec4{
    display: none;
  }
  .sec1_bg{
    height: 100vh;
    background: url(../../photos/home_sec1_bg.png)  no-repeat center center fixed ;
  
  background-size: 100% 100%;
  overflow: hidden;
  }
  .rectangel_home{
 
  }
  .sec1_home{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sec_1_home_text h3{
    margin-inline-start: -100px;
    
  }
  .sec_1_home_text p{
    margin-inline-start: -100px;
    
  }
  .home_sec2 h3{
    color: #004B6E;
    text-decoration: underline;
    text-decoration-color: #004B6E ;
    text-underline-offset: 14px;
  }
  .home_sec4 h3{
    color: #004B6E;
    text-decoration: underline;
    text-decoration-color: #004B6E ;
    text-underline-offset: 14px;
  }
  .home_sec4 h2 {
    color: #f1f1f1;

  }
  .home_sec4 h4 {
    color: #f1f1f1;

  }
  .home_sec4 p {
    color: #f1f1f1;

  }
  .sec2_text_color span{
    color: #4FA0C8;
  }
  .sec4_text_color h1{
    color: #4FA0C8;
  }
  .sec4_home{
    background-image:linear-gradient( 90deg, rgba(79, 160, 200, 1) , rgba(0, 75, 110, 1))  ;
    
  }
  .sec4_btn{
    width: 248px;
      background-color: #ffffff;
      color:#000000 ;
      border-radius: 5px;
      font-size: 24px;
      border-style: solid;
      border-width: 0px;
    }
  .sec5_btn{
  width: 248px;
    background-color: #4FA0C8;
    color:#ffffff ;
    border-radius: 5px;
    font-size: 24px;
    border-style: solid;
    border-width: 0px;
  }
  .sec6_bg{
    background-image: url("../../photos/home-sec6.png");
    background-size:100% 100%;
    background-repeat: no-repeat;
    height: 535px;
    margin-bottom: 300px;

  }
  .home_sec6{
    margin-top: -120px;
  }
  .home_sec6 h2{
    color: #ffffff;

  }
  .home_sec6 p{
    color: #ffffff;

  }
  .home_sec6 h3{
    color: #ffffff;
    text-decoration: underline;
    text-decoration-color: #ffffff ;
    text-underline-offset: 14px;
  }
  .home_sec6 span{
    color: #4FA0C8;
  }
  .home_sec6-form{
    background-color: white;
    margin-top: 120px;
    border-radius: 10px;
    box-shadow: 10px 10px 30px  #00000027;
  }
  .sec6_input{
    display: block;
    width: 80%;
    height: 50px;
    background-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 2px;
    border-color: #2929294f ;
    border-radius: 6px;
  }
  .sec6_input_message{
    display: block;
    width: 80%;
    padding-bottom: 120px;
    background-color: rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 2px;
    border-color: #2929294f;
    border-radius: 6px;
  }
  .sec6_input::placeholder{
  padding-left: 10px;
  color: #2929294f;
  
  }
  .sec6_input_message::placeholder{
    color: #2929294f;
  }
  .sec6_btn{
    width: 80%;

    background-color: #4FA0C8;
    color:#ffffff ;
    border-radius: 8px;
    font-size: 24px;
    border-style: solid;
    border-width: 0px;
  }

 
}
@media  screen and (max-width: 1000px) and (min-width:  720px)  {
  .display_hide2_sec4{
    display: none;
  }
  .display_hide{
    display: none;
  }
  .sec1_bg{
    height: 800px;
    background: url(../../photos/home_sec1_bg.png)  no-repeat center center fixed ;
  
  background-size: 100% 100%;
  overflow: hidden;
  }
  .rectangel_home{
    width:250px;
    height: 280px;
  }
  .sec1_home{
    margin-top: 140px;
    margin-inline-start: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sec_1_home_text{
    margin-inline-start: -80px;
  }

  .home_sec2 h3{
    color: #004B6E;
    text-decoration: underline;
    text-decoration-color: #004B6E ;
    text-underline-offset: 14px;
  }
  .home_sec4 h3{
    color: #004B6E;
    text-decoration: underline;
    text-decoration-color: #004B6E ;
    text-underline-offset: 14px;
  }
  .home_sec4 h2 {
    color: #f1f1f1;

  }
  .home_sec4 h4 {
    color: #f1f1f1;

  }
  .home_sec4 p {
    color: #f1f1f1;

  }
  .sec2_text_color span{
    color: #4FA0C8;
  }
  .sec4_text_color h1{
    color: #4FA0C8;
  }
  .sec4_home{
    background-image:linear-gradient( 90deg, rgba(79, 160, 200, 1) , rgba(0, 75, 110, 1))  ;
    
  }
  .sec4_btn{
    width: 248px;
      background-color: #ffffff;
      color:#000000 ;
      border-radius: 5px;
      font-size: 24px;
      border-style: solid;
      border-width: 0px;
    }
  .sec5_btn{
  width: 248px;
    background-color: #4FA0C8;
    color:#ffffff ;
    border-radius: 5px;
    font-size: 24px;
    border-style: solid;
    border-width: 0px;
  }
  /* .sec6_bg{
    background-image: url("../../photos/home-sec6.png");
    background-size:100% 100%;
    background-repeat: no-repeat;
    height: 535px;
    margin-bottom: 300px;

  }
  .home_sec6{
    margin-top: -120px;
  }
  .home_sec6 h2{
    color: #ffffff;

  }
  .home_sec6 p{
    color: #ffffff;

  }
  .home_sec6 h3{
    color: #ffffff;
    text-decoration: underline;
    text-decoration-color: #ffffff ;
    text-underline-offset: 14px;
  }
  .home_sec6 span{
    color: #4FA0C8;
  }
  .home_sec6-form{
    background-color: white;
    margin-top: 120px;
    border-radius: 10px;
    box-shadow: 10px 10px 30px  #00000027;
  }
  .sec6_input{
    display: block;
    width: 80%;
    height: 50px;
    background-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 2px;
    border-color: #2929294f ;
    border-radius: 6px;
  }
  .sec6_input_message{
    display: block;
    width: 80%;
    padding-bottom: 120px;
    background-color: rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 2px;
    border-color: #2929294f;
    border-radius: 6px;
  }
  .sec6_input::placeholder{
  padding-left: 10px;
  color: #2929294f;
  
  }
  .sec6_input_message::placeholder{
    color: #2929294f;
  }
  .sec6_btn{
    width: 80%;

    background-color: #4FA0C8;
    color:#ffffff ;
    border-radius: 8px;
    font-size: 24px;
    border-style: solid;
    border-width: 0px;
  } */
  .sec6_bg{
  
    height: 100%;
  padding-bottom: 50px;
   }
   .home_sec6{
   
   }
   .home_sec6 h2{
     color: black;
  
   }
   .home_sec6 h3{
     color: #004B6E;
     text-decoration: underline;
     text-decoration-color: #004B6E ;
     text-underline-offset: 14px;
   }
   .home_sec6 span{
     color: #4FA0C8;
   }
   .home_sec6-form{
     background-color: white;
     margin-top: 50px;
     
     border-radius: 10px;
     box-shadow: 10px 10px 30px  #00000027;
   }
   .sec6_input{
    display: block;
    width: 80%;
    height: 50px;
    background-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 2px;
    border-color: #2929294f ;
    border-radius: 6px;
  }
  .sec6_input_message{
    display: block;
    width: 80%;
    padding-bottom: 120px;
    background-color: rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 2px;
    border-color: #2929294f;
    border-radius: 6px;
  }
  .sec6_input::placeholder{
  padding-left: 10px;
  color: #2929294f;
  
  }
  .sec6_input_message::placeholder{
    color: #2929294f;
  }
  .sec6_btn{
    width: 80%;

    background-color: #4FA0C8;
    color:#ffffff ;
    border-radius: 8px;
    font-size: 24px;
    border-style: solid;
    border-width: 0px;
  }
}

@media  screen and (max-width:719px) and (min-width:0px)  {



  .display_hide1_sec4{
    display: none;
  }
  .display_hide{
    display: none;
  }
  .sec1_bg{
    height: 600px;
    background: url(../../photos/home_sec1_bg_media.png)  no-repeat center center fixed ;
  
  background-size: 100% 100%;
  overflow: hidden;
  }
  .rectangel_home{
    width:250px;
    height: 280px;
  }
  .sec1_home{
    margin-top: 70px;
    margin-inline-start: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sec_1_home_text h3{
    margin-inline-start: -100px;
    font-size: 17px;
  }
  .sec_1_home_text p{
    margin-inline-start: -100px;
    font-size: 12px;
  }

  .home_sec2 h3{
    color: #004B6E;
    text-decoration: underline;
    text-decoration-color: #004B6E ;
    text-underline-offset: 14px;
  }
  .home_sec4 h3{
    color: #004B6E;
    text-decoration: underline;
    text-decoration-color: #004B6E ;
    text-underline-offset: 14px;
  }
  .home_sec4 h2 {
    color: #f1f1f1;

  }
  .home_sec4 h4 {
    color: #f1f1f1;
    margin-inline-start: 20px;

  }
  .home_sec4 p {
    color: #f1f1f1;
    margin-inline-start: 20px;

  }
  .home_sec4 img{
    margin-inline-start: 18px;
  }
  .sec2_text_color span{
    color: #4FA0C8;
  }
  .sec4_text_color h1{
    color: #4FA0C8;
  }
  .sec4_home{
    background-image:linear-gradient( 90deg, rgba(79, 160, 200, 1) , rgba(0, 75, 110, 1))  ;
    
  }
  .sec4_btn{
    width: 248px;
      background-color: #ffffff;
      color:#000000 ;
      border-radius: 5px;
      font-size: 24px;
      border-style: solid;
      border-width: 0px;
    }
  .sec5_btn{
  width: 248px;
    background-color: #4FA0C8;
    color:#ffffff ;
    border-radius: 5px;
    font-size: 24px;
    border-style: solid;
    border-width: 0px;
  }
  .sec6_bg{
    
   height: 100%;
padding-bottom: 50px;
  }
  .home_sec6{
  
  }
  .home_sec6 h2{
    color: black;

  }
  .home_sec6 h3{
    color: #004B6E;
    text-decoration: underline;
    text-decoration-color: #004B6E ;
    text-underline-offset: 14px;
  }
  .home_sec6 span{
    color: #4FA0C8;
  }
  .home_sec6-form{
    background-color: white;
    margin-top: 50px;
    
    border-radius: 10px;
    box-shadow: 10px 10px 30px  #00000027;
  }
  .sec6_input{
    display: block;
    width: 80%;
    height: 50px;
    background-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 2px;
    border-color: #2929294f ;
    border-radius: 6px;
  }
  .sec6_input_message{
    display: block;
    width: 80%;
    padding-bottom: 120px;
    background-color: rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 2px;
    border-color: #2929294f;
    border-radius: 6px;
  }
  .sec6_input::placeholder{
  padding-left: 10px;
  color: #2929294f;
  
  }
  .sec6_input_message::placeholder{
    color: #2929294f;
  }
  .sec6_btn{
    width: 80%;

    background-color: #4FA0C8;
    color:#ffffff ;
    border-radius: 8px;
    font-size: 24px;
    border-style: solid;
    border-width: 0px;
  }

  
}




