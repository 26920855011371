@media screen and (max-width: 1000px) and (min-width: 0px) {
  .services_bg {
    background-image: url("../../photos/aboutus_bg_mobile.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    overflow: hidden;
  }
  .services_bg_ar {
    background-image: url("../../photos/aboutus_bg_mobile.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    overflow: hidden;
  }
  .services_sec2 h4 {
    color: #004b6e;
    text-decoration: underline;
    text-decoration-color: #004b6e;
    text-underline-offset: 14px;
  }
  .services_sec2 span {
    color: #4fa0c8;
  }

  .services_sec3 h4 {
    color: #004b6e;
  }

  .services_sec3 p {
    color: #000000;
  }

  .Aboutus_text {
    margin-top: 200px;
  }
  .Aboutus_text h3 {
    color: #004b6e;
    text-decoration: underline;
    text-decoration-color: #004b6e;
    text-underline-offset: 14px;
  }
  .Aboutus_text h1 {
    font-size: 40px;
  }
  .Aboutus_text p {
    font-size: 13px;
  }
  .about_img {
    width: 400px;
  }
  .about_show {
    display: none;
  }
  .about_text1 {
    margin-top: 40px;
    margin-inline-start: 50px;
  }
  .aboutus_sec4 li {
    position: relative;
    color: #004b6e;
    font-size: 20px;
  }

  .about_img {
    width: 350px;
    display: flex;
    justify-content: center;
  }
  .about_text1 {
    text-align: center;
  }
  .about_text {
    text-align: center;
  }
  .about_hidden {
    display: none;
  }
  .about_show {
    margin-top: 20px;
    display: flex;
  }
}

@media screen and (min-width: 1001px) {
  .services_bg {
    background-image: url("../../photos/services_bg.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    overflow: hidden;
  }
  .services_bg_ar {
    background-image: url("../../photos/services_bg_ar.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    overflow: hidden;
  }
  .services_sec2 h4 {
    color: #004b6e;
    text-decoration: underline;
    text-decoration-color: #004b6e;
    text-underline-offset: 14px;
  }
  .services_sec2 span {
    color: #4fa0c8;
  }

  .services_sec3 h4 {
    color: #004b6e;
  }
  .services_sec3 p {
    color: #000000;
  }

  .Aboutus_text {
    margin-top: 200px;
  }
  .Aboutus_text h3 {
    color: #004b6e;
    text-decoration: underline;
    text-decoration-color: #004b6e;
    text-underline-offset: 14px;
  }
  .Aboutus_text h1 {
    font-size: 40px;
  }
  .Aboutus_text p {
    font-size: 13px;
  }
  .about_img {
    width: 400px;
  }
  .about_show {
    display: none;
  }
  .about_text1 {
    margin-top: 40px;
    margin-inline-start: 50px;
  }
  .aboutus_sec4 li {
    position: relative;
    color: #004b6e;
    font-size: 20px;
  }
}
