
  .languag_btn{

    color: rgb(255, 255, 255);
  
  }
  .languag_btn:hover{
    color:#1E9BDE ;
  }

 
  .logo{
    width: 70px;
    height: 70px;
  }
  .nav_m_inline_start{
   margin-inline-start: 80px; 
   margin-bottom: -20px;
   color: rgb(255, 255, 255);
  }
  .Nav_Link{
    margin-inline-start: 50px;
    margin-bottom: -20px;
    color: rgb(255, 255, 255);
  
  }
  .Nav_Link:hover{
    color:#1E9BDE ;
  } 

  .Nave_media{
    display: flex;
    justify-content: center;
    margin-top: -20px;
  }
  .ms-9{
    margin-inline-start: 150px;
  }
  @media  screen and (max-width: 1000px) {
    .ms-9{
      margin-inline-start:0px;
    }
    .nav_m_inline_start{
      margin: 20px;
      color: rgb(255, 255, 255);
     }
     .Nav_Link{
      margin: 20px;
       color: rgb(255, 255, 255);
     
     }
    .Nave_media{
     
  display: block;
    }
    .Nav_Link{
  
      color: rgb(255, 255, 255);
    
    }
    .Nav_Link:hover{
      color:#000000 ;
    } 
  
  
  
  }