@media  screen  and (min-width: 1001px) {
    .aboutus_hide_img{
    display: none;
    }
    .aboutus_show_img{

    }
    .Aboutus_bg{
        background-image: url("../../photos/aboutUs-sec1_bg.png");
        background-position:  right;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        
        overflow: hidden;
       
    }
    .Aboutus_bg_ar{
        background-image: url("../../photos/aboutUs-sec1_bg_ar.png");
        background-position:  right;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      
        overflow: hidden;
    }
    
    .aboutus_sec2 h2{
        color: #4FA0C8;
    }
    .Aboutus_text_sec1{
       margin-top: 100px;
    }
    .Aboutus_text_sec1 h3{
        color: #004B6E;
        text-decoration: underline;
        text-decoration-color: #004B6E ;
        text-underline-offset: 14px;
        
      }
    .Aboutus_text_sec1 h1{
        font-size: 40px;
    }
    .Aboutus_text_sec1 p{
        font-size: 13px;
    }
    .about_img{
        width: 400px;
    }
    .about_show{
    display: none;
    }
    .about_text_sec2{
        margin-top: 40px;
        margin-inline-start: 50px;
    }
    .aboutus_sec4 li{
        
        color: #004B6E;
        font-size: 20px;
    }
}
@media  screen and (max-width: 1000px) and (min-width: 0px) {
    .aboutus_hide_img{
    
    }
    .aboutus_show_img{
        display: none;
    }
    .Aboutus_bg{
        background-image: url("../../photos/aboutus_bg_mobile.png");
        background-position:  right;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        
        overflow: hidden;
    }
    .Aboutus_bg_ar{
        background-image: url("../../photos/aboutus_bg_mobile.png");
        background-position:  right;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        
        overflow: hidden;
    }
    .aboutus_sec2 h2{
        color: #4FA0C8;
    }
    .sec1_media{
       margin-top: 150px;
    }
    .Aboutus_text_sec1{
      
    }
    .Aboutus_text_sec1 h3{
        color: #004B6E;
        text-decoration: underline;
        text-decoration-color: #004B6E ;
        text-underline-offset: 14px;
      }
    .Aboutus_text_sec1 h1{
        font-size: 40px;
    }
    .Aboutus_text_sec1 p{
        font-size: 13px;
    }
    .about_img{
        width: 400px;
    }
    .about_show{
    display: none;
    }
    .about_text_sec2{
        margin-top: 40px;
        
       
    }
    .aboutus_sec4 li{
        color: #004B6E;
        font-size: 10px;
    }

}



/* @media screen and (max-width: 1140px) {
    .About_fs{
        font-size: 40px;
    }
    .About_bg{
        background-image: url("");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 400px;
    }


.about_img{
    width: 350px;
    display: flex;
    justify-content: center;
}
.about_text1{
    text-align: center;
    }
.about_text{
text-align: center;
}
.about_hidden{
    display: none;
}
.about_show{
    margin-top: 20px;
    display: flex;
}
  } */



