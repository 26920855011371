.detail_underline{
width: 100%;
height: 2px;
background-color: rgba(0, 0, 0, 0.301);
}
.detail_form_text span{
  color: #4FA0C8;
}
@media screen and (min-width: 992px) {
  .details_nav{
    height: 90px;
    width: 100%;
    background-color:#004B6E ;
  }
.Details_requist{
    
    
    height: auto;
    width: 100%;
    border-style: solid;
    border-color: rgba(97, 97, 97, 0.644);
    border-width: 2px;
    border-radius: 5px;
}
.Details_requist_input{
    width: 85%;
    height: 40px;
    border-radius: 5px;
    border-style: solid;
    border-color: rgba(97, 97, 97, 0.644);
    border-width: 1px;
}
.Details_requist_mess{
  width: 85%;
  height: 100px;
  border-radius: 5px;
  border-style: solid;
  border-color: rgba(97, 97, 97, 0.644);
  border-width: 1px;
}
.Details_request_btn{
    width: 200px;
    height: 40px;
    color: white;
    border-radius: 5px;
    border-style: solid;
    border-color: rgba(97, 97, 97, 0.644);
    border-width: 1px;
    background-color: #003075;
    margin-bottom: 40px;
}
.Details_card .card{
    width: 230px;
    height: 300px;
    border-width: 0px;
    background: linear-gradient(180deg, rgba(229,229,229,1) 1%, rgb(255, 255, 255) 100%);
  }
  .Details_card .card-body{
    width: 230px;
    height: 300px;
    background: linear-gradient(180deg, rgba(229,229,229,1) 1%, rgb(255, 255, 255) 100%);
  border-bottom-left-radius:10px ;
  border-bottom-right-radius:10px ;
    box-shadow: 10px 10px 30px  #e5e5e5;
  }

  .Details_card p {
    font-size: 12px;
  }
}
@media screen and (max-width: 993px) {
    /* .details_media_center{
        text-align: center;
    } */
    .details_nav{
      height: auto;
      
      background-color:#004B6E ;
    }
    .Details_requist{
        
        
        height: auto;
        width: 100%;
        border-style: solid;
        border-color: rgba(97, 97, 97, 0.644);
        border-width: 2px;
        border-radius: 5px;
    }
    .Details_requist_input{
      width: 85%;
        height: 40px;
        border-radius: 5px;
        border-style: solid;
        border-color: rgba(97, 97, 97, 0.644);
        border-width: 1px;
    }
    .Details_requist_mess{
      width: 85%;
      height: 100px;
      border-radius: 5px;
      border-style: solid;
      border-color: rgba(97, 97, 97, 0.644);
      border-width: 1px;
    }
    .Details_request_btn{
        width: 200px;
        height: 40px;
        color: white;
        border-radius: 5px;
        border-style: solid;
        border-color: rgba(97, 97, 97, 0.644);
        border-width: 1px;
        background-color: #003075;
        margin-bottom: 40px;
    }
    .Details_card .card{
        width: 300px;
        height: 300px;
        border-width: 0px;
        background: linear-gradient(180deg, rgba(229,229,229,1) 1%, rgb(255, 255, 255) 100%);
      }
      .Details_card .card-body{
        width: 300px;
        height: 300px;
        background: linear-gradient(180deg, rgba(229,229,229,1) 1%, rgb(255, 255, 255) 100%);
      border-bottom-left-radius:10px ;
      border-bottom-right-radius:10px ;
        box-shadow: 10px 10px 30px  #e5e5e5;
      }
    
      .Details_card p {
        font-size: 12px;
      }
    }