@media  screen and (max-width: 1000px) and (min-width: 0px) {
  .ContactUs_fs {
    font-size: 60px;
  }

  .ContactUs_bg {
    background-image: url("../../photos/aboutus_bg_mobile.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    
    overflow: hidden;
  }
  .ContactUs_bg_ar{
    background-image: url("../../photos/aboutus_bg_mobile.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    
    overflow: hidden;
  }
  .contactus_text h4{
    color: #004B6E;
    text-decoration: underline;
    text-decoration-color: #004B6E ;
    text-underline-offset: 14px;
  }
  .contactus_text h2{
    color: #4FA0C8;

  }
  .icons_color{
    color: #4FA0C8;
    font-size: 20px;
    margin-top: 12px;
  }
  .contactus_text span{
    color: #4FA0C8;

  }
  .sec2-contact_input {
    display: block;
    width: 80%;
    height: 50px;
    background-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 2px;
    border-color: #2929294f ;
    border-radius: 6px;
  }
  .sec2-contact_input_mass {
    display: block;
    width: 80%;
    padding-bottom: 110px;
    background-color: rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 2px;
    border-color: #2929294f;
    border-radius: 6px;
  }
  .sec2-contact_input::placeholder {
    padding-inline-start: 10px;
    color: black;
  }
  .sec2-contact_input_mass::placeholder {
    padding-inline-start: 10px;
    color: black;
  }

  .contactUs_bn {
    width: 80%;

    background-color: #4FA0C8;
    color:#ffffff ;
    border-radius: 8px;
    font-size: 24px;
    border-style: solid;
    border-width: 0px;
  }
  .contactus_media_space{
    
    
  }
}
@media  screen  and (min-width: 1001px) {
  .ContactUs_fs {
    font-size: 60px;
  }

  .ContactUs_bg {
    background-image: url("../../photos/Contactus_bg.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: 100% 100%;
   
    overflow: hidden;
  }
  .ContactUs_bg_ar{
    background-image: url("../../photos/Contactus_bg_ar.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: 100% 100%;
   
    overflow: hidden;
  }
  .contactus_text h4{
    color: #004B6E;
    text-decoration: underline;
    text-decoration-color: #004B6E ;
    text-underline-offset: 14px;
  }
  .contactus_text h2{
    color: #4FA0C8;

  }
  .icons_color{
    color: #4FA0C8;
    font-size: 20px;
    margin-top: 12px;
  }
  .contactus_text span{
    color: #4FA0C8;

  }
  .sec2-contact_input {
    display: block;
    width: 80%;
    height: 50px;
    background-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 2px;
    border-color: #2929294f ;
    border-radius: 6px;
  }
  .sec2-contact_input_mass {
    display: block;
    width: 80%;
    padding-bottom: 110px;
    background-color: rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 2px;
    border-color: #2929294f;
    border-radius: 6px;
  }
  .sec2-contact_input::placeholder {
    padding-inline-start: 10px;
    color: black;
  }
  .sec2-contact_input_mass::placeholder {
    padding-inline-start: 10px;
    color: black;
  }

  .contactUs_bn {
    width: 80%;

    background-color: #4FA0C8;
    color:#ffffff ;
    border-radius: 8px;
    font-size: 24px;
    border-style: solid;
    border-width: 0px;
  }
  .contactus_media_space{
    display: flex;
    justify-content: space-between;
    
  }
}
